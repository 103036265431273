$(document).ready(function() {
    $('#extended-search').on('show.bs.collapse', function() {
        $('.show-extended-search-button').find('i').addClass('rotate-180');

        $('.extended-search-item').each((i, e) => {
            e.removeAttribute('disabled')
        });
    });

    $('#extended-search').on('hide.bs.collapse', function() {
        $('.show-extended-search-button').find('i').removeClass('rotate-180');

        $('.extended-search-item').each((i, e) => {
            e.setAttribute('disabled', 'disabled')
        });
    });

    function switchSearchForm() {
        let from = $('input[type=radio][name=search_model]:not(:checked)')[0].value;
        let to = $('input[type=radio][name=search_model]:checked')[0].value;

        $('#extended-search-' + from).fadeOut(100);
        setTimeout(function(){
            $('#extended-search-' + to).fadeIn(100);
        }, 100)
    }
    if ($('#extended-search').length && $('.extended-search-services').length) {
        switchSearchForm();
    }

    $('input[type=radio][name=search_model').change(switchSearchForm);
});
